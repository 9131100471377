import { DEPLOY_ENV, SENTRY, getClientSideVariables } from '@config';
import type { Event } from '@sentry/nextjs';
import type { BrowserOptions, NodeOptions } from '@sentry/nextjs';

const { DEPLOY_ENV: CLIENT_SIDE_DEPLOY_ENV } = getClientSideVariables();
type Runtime = 'Client Side' | 'Server Side' | 'Edge';

export function commonSentryConfig(
  runtime: Runtime,
): BrowserOptions | NodeOptions {
  function beforeSend(event: Event) {
    // Check if the User-Agent is Synthetic-Monitoring-BrowserStack to remove test runs from Sentry
    const userAgent = event.request?.headers?.['User-Agent'];

    if (userAgent === 'Synthetic-Monitoring-BrowserStack') {
      return null;
    }

    event.tags = {
      ...event.tags,
      ...(typeof window !== 'undefined' && { clientUrl: window.location.href }),
    };

    return event;
  }

  return {
    enabled: CLIENT_SIDE_DEPLOY_ENV === 'prod' || DEPLOY_ENV === 'prod',
    environment:
      runtime === 'Client Side' ? CLIENT_SIDE_DEPLOY_ENV : DEPLOY_ENV,
    dsn: SENTRY.DSN,
    tracesSampleRate: 1.0,
    initialScope: {
      tags: {
        exception_config: runtime,
      },
    },
    ignoreErrors: [
      'ContextualSupportContent.labels',
      'ContextualSupportContent.errorMessage',
      'VIN is not registered for market',
    ],
    beforeSend,
  };
}
