import type { ClientSideRuntimeVariables } from '@contexts/client-side-runtime-variables/client-side-runtime-variables.context';
import type { DeployEnv } from '@vcc-www/cookie-service-embed';
import type { TestQueryParams } from 'src/types/page-search-params';
import { VOLVO_APP_CONFIG as VOLVO_APP } from './volvo-app';

// Apollo
// Client cannot access process.env, so we need to use NEXT_PUBLIC_ prefix
const APOLLO_PROD_OVERRIDE = 'https://graph.volvocars.com/graphql';

const APOLLO_URL =
  process.env.APOLLO_URL ||
  process.env.NEXT_PUBLIC_APOLLO_URL ||
  'https://qagraph.volvocars.com/graphql';

const APOLLO = {
  URL: APOLLO_URL,
  PROD_OVERRIDE_URL: APOLLO_PROD_OVERRIDE,
};

// Campaign page
const CAMPAIGN_PAGE = {
  // If this is set to 0, the page will be revalidated on every request
  REVALIDATION_PERIOD: Number.parseInt(
    process.env.CAMPAIGN_PAGE_REVALIDATION_PERIOD || '0',
  ),
  SHOULD_CACHE: process.env.CAMPAIGN_PAGE_SHOULD_CACHE === 'true',
};

// Cart service
const CART_SERVICE = {
  URL:
    process.env.CART_SERVICE_URL ||
    'https://apigw-ext-qa.euwest1.staging.volvo.care/storefront',
  API_KEY: process.env.CART_SERVICE_API_KEY || '',
};

// Content Delivery
const CONTENT_DELIVERY_API_KEY = process.env.CONTENT_DELIVERY_API_KEY || '';
const CONTENT_DELIVERY = {
  API_KEY: CONTENT_DELIVERY_API_KEY,
};

// Content Management
const CONTENT_MANAGEMENT_VCC_API_KEY =
  process.env.CONTENT_MANAGEMENT_VCC_API_KEY || '';

// Content Stack
const CONTENT_STACK = {
  ENVIRONMENT: process.env.CONTENTSTACK_ENVIRONMENT,
  DATA_SOURCE: process.env.CONTENTSTACK_DATASOURCE,
};

export const baseUrlOrigin: Record<DeployEnv, string> = {
  dev: 'http://localhost:3000',
  test: 'https://testwww.volvocars.com',
  qa: 'https://qawww.volvocars.com',
  prod: 'https://www.volvocars.com',
};

const RAW_DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV || 'dev';
function isDeployEnv(env: string): asserts env is DeployEnv {
  if (!['dev', 'test', 'qa', 'prod'].find((curr) => curr === env)) {
    throw new Error('NEXT_PUBLIC_DEPLOY_ENV is missing');
  }
}

isDeployEnv(RAW_DEPLOY_ENV);

const DEPLOY_ENV = RAW_DEPLOY_ENV;

const BASE_URL =
  typeof window !== 'undefined'
    ? window.location.origin
    : baseUrlOrigin[DEPLOY_ENV];

function getClientSideVariables(): ClientSideRuntimeVariables {
  return { BASE_URL, DEPLOY_ENV };
}

// Headers
const QA_HEADER = process.env.QA_HEADER;
const TESTWWW_HEADER = process.env.TESTWWW_HEADER;

// IPIP
const IPIP_URL = process.env.IPIP_URL || '';

// LaunchDarkly
const LAUNCHDARKLY_CLIENT_ID = process.env.LAUNCHDARKLY_CLIENT_ID || '';
const LAUNCHDARKLY_CLIENT_ID_GLOBAL =
  process.env.LAUNCHDARKLY_CLIENT_ID_GLOBAL || '';
const LAUNCHDARKLY_REST_API_KEY = process.env.LAUNCHDARKLY_REST_API_KEY || '';

const LAUNCHDARKLY = {
  CLIENT_ID: LAUNCHDARKLY_CLIENT_ID,
  CLIENT_ID_GLOBAL: LAUNCHDARKLY_CLIENT_ID_GLOBAL,
  REST_API_KEY: LAUNCHDARKLY_REST_API_KEY,
};

//  Page naming
const PAGE_NAMING = {
  CampaignPage: 'CampaignPage',
  CollectionListingPage: 'CollectionListingPage',
  InsurancePage: 'InsurancePage',
  OrderPage: 'OrderPage',
  ProductDetailsPage: 'ProductDetailsPage',
  ProductListingPage: 'ProductListingPage',
  MarketStatusPage: 'MarketStatusPage',
} as const;

// Preview query params
const PREVIEW_QUERY_PARAMS: Record<
  keyof TestQueryParams,
  keyof TestQueryParams
> = {
  dictionaries: 'dictionaries',
  overrideFlags: 'overrideFlags',
  preview: 'preview',
  showSources: 'showSources',
  errorReporting: 'errorReporting',
  triggerQueryError: 'triggerQueryError',
  useDefaultMarketConfig: 'useDefaultMarketConfig',
  useProdData: 'useProdData',
  overrideAvailableAt: 'overrideAvailableAt',
  pdpNoIgnore: 'pdpNoIgnore',
};

// Sentry
const SENTRY = {
  DSN: 'https://fdac86f43df9d1d1d5a25e1f2ef4c200@sentry-relay-weu.volvocars.biz/4507821374308352',
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
};

// Session service
const SESSION_SERVICE_API_KEY = process.env.SESSION_SERVICE_API_KEY || '';
const SESSION_SERVICE_URL =
  process.env.SESSION_SERVICE_URL ||
  'https://staging-euwest1.api.volvo.care/api/internal/sessionservice/v2/sessions';

const SESSION_SERVICE = {
  URL: SESSION_SERVICE_URL,
  API_KEY: SESSION_SERVICE_API_KEY,
};

// Storybook
const STORYBOOK_URL =
  'https://storefront-ss.lemoncoast-ae00e73e.westeurope.azurecontainerapps.io/';

// Notion
const NOTION = {
  NOTION_API_KEY: process.env.NOTION_API_KEY,
};

export {
  APOLLO,
  BASE_URL,
  CAMPAIGN_PAGE,
  CART_SERVICE,
  CONTENT_DELIVERY,
  CONTENT_MANAGEMENT_VCC_API_KEY,
  CONTENT_STACK,
  DEPLOY_ENV,
  getClientSideVariables,
  IPIP_URL,
  LAUNCHDARKLY,
  NOTION,
  PAGE_NAMING,
  PREVIEW_QUERY_PARAMS,
  QA_HEADER,
  TESTWWW_HEADER,
  SENTRY,
  SESSION_SERVICE,
  STORYBOOK_URL,
  VOLVO_APP,
};
